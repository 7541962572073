export const showProgress = (progresRef) => {
  const progress = progresRef.current;
  const pageContentHeight = document.body.offsetHeight;
  const windowHeight = window.innerHeight;
  const clientPassed = window.scrollY;
  const pageHiddenContentHeight = pageContentHeight - windowHeight;
  const scrollPercentValue = clientPassed / pageHiddenContentHeight * 100;
  progress.value = scrollPercentValue;
};

export const scrollToTop = () => {
  let currentTopPosition = window.pageYOffset;
  let timerID;

  const scroll = () => {
    if (currentTopPosition > 0) {
      window.scrollTo(0, currentTopPosition);
      currentTopPosition -= 250;
      timerID = setTimeout(scroll, 20);
    } else {
      window.scrollTo(0, 0);
      clearTimeout(timerID);
    }
  };

  scroll(timerID);
};

export const scrollToSection = (sectionTargetHash) => {
  if (!sectionTargetHash) return;

  const $header = document.querySelector('#header');
  const $section = document.querySelector(`${sectionTargetHash}`);

  const headerHeight = $header.offsetHeight;
  const sectionTopPosition = $section.offsetTop;
  const targetTopPosition = sectionTopPosition - headerHeight;
  let currentTopPosition = window.pageYOffset;
  let timerID;

  const scrollToBottom = () => {
    if (currentTopPosition < targetTopPosition) {
      window.scrollTo(0, currentTopPosition);
      currentTopPosition += 250;
      timerID = setTimeout(scrollToBottom, 20);
    } else {
      window.scrollTo(0, targetTopPosition);
      clearTimeout(timerID);
    }
  };

  const scrollToTop = () => {
    if (currentTopPosition > targetTopPosition) {
      window.scrollTo(0, currentTopPosition);
      currentTopPosition -= 250;
      timerID = setTimeout(scrollToTop, 20);
    } else {
      window.scrollTo(0, targetTopPosition);
      clearTimeout(timerID);
    }
  };

  if (currentTopPosition < targetTopPosition) {
    scrollToBottom();
  }

  if (currentTopPosition > targetTopPosition) {
    scrollToTop();
  }
};

export const getCurrentTime = () => {
  const date = new Date();

  const hors = date.getHours() < 10
    ? `0${date.getHours()}`
    : date.getHours();

  const minutes = date.getMinutes() < 10
    ? `0${date.getMinutes()}`
    : date.getMinutes();

  return `${hors}:${minutes}`;
};

export const debounce = (func, delay) => {
  let timerID;
  return (...arg) => {
    clearTimeout(timerID);
    const callback = () => func.apply(this, arg);
    timerID = setTimeout(callback, delay);
  };
};

export const throttle = (func, delay) => {
  let timerID = null;

  return (...args) => {
    if (timerID) return;

    const callback = () => func.apply(this, args);

    timerID = setTimeout(() => {
      callback();
      clearTimeout(timerID);
      timerID = null;
    }, delay);
  };
};

export const trimText = (string, number) => {
  string = string.slice(0, number)
  let arr = string.split(' ');
  arr.length--;
  string = arr.join(' ')
  return string;
}