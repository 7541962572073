import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { dataReducer } from './slices/dataSlice';
import { themeReducer } from './slices/themeSlice';
import { headerReducer } from './slices/headerSlice';
import { postsReducer } from './slices/postsSlice';
import { postReducer } from './slices/postSlice';
import { contactsReducer } from './slices/contactsSlice';
import { buttonReducer } from './slices/buttonSlice';
import { homePageReducer } from './slices/homePageSlice';

const rootReducer = combineReducers({
  themeReducer,
  dataReducer,
  headerReducer,
  postsReducer,
  contactsReducer,
  postReducer,
  buttonReducer,
  homePageReducer,
});

export const store = configureStore({
  reducer: rootReducer
});
